import React from "react";
import { graphql } from "gatsby";

import {
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  ContainerContent,
  BannerPage,
  ContainerContentFixed,
  TitleSection,
  InternalLink,
  ButtonSecondary,
} from "../components";
import Img from "gatsby-image";

export default ({ data }) => {
  const review = data.allReviewsJson.nodes[0];

  return (
    <Layout>
      <SEO title={review.title} />
      <BannerPage
        title={`Review by ${review.client}`}
        subTitle={review.title}
      />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={review.imagePath.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed style={{ textAlign: "center" }}>
          <section style={{ textAlign: "left" }}>
            <TitleSection>{review.title}</TitleSection>
            <b>{review.date}</b>
            <br />
            <br />
            {review.review.map(row => (
              <p>{row}</p>
            ))}

            {review.homeDesignSlug && (
              <ButtonSecondary>
                <InternalLink url={review.homeDesignSlug}>
                  See their home
                </InternalLink>{" "}
              </ButtonSecondary>
            )}
          </section>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        //title={"Read more Reviews"}

        secondaryText={"Read other reviews"}
        secondaryLink={InternalLinkTypes.Reviews}
      />
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    allReviewsJson(filter: { slug: { eq: $path } }) {
      nodes {
        client
        title
        platform
        review
        date
        slug
        homeDesignSlug
        imagePath {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
